@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  min-height: var(--height-app);
  min-width: 100%;

  font-family: Wotfard, sans-serif;
  padding: 0;
  margin: 0;
  font-size: var(--font-size-md);
}

#__next {
  min-height: var(--height-app);
  min-width: 100%;
}

input,
textarea {
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.grecaptcha-badge {
  visibility: hidden;
}

:root {
  /* height */
  --height-app: 100vh;
  --height-navbar: 57px;
  --height-navbar-sm: 52px;
  --height-navbar-banner: 32px;

  /* width */
  --max-width-app: 1100px;

  /* z-index */
  --z-index-float: 50;
  --z-index-nav: 100;
  --z-index-float-on-popper: 1310; // MUI popper has 1300 z index by default

  /* font size */
  --font-size-xxl: 32px;
  --font-size-xl: 28px;
  --font-size-lg: 24px;
  --font-size-bg: 21px;
  --font-size-hi: 19px; // size highlight
  --font-size-md: 17px;
  --font-size-sm: 15px;
  --font-size-xs: 13px;

  /* line height */
  --line-height-md: 25px;

  /* color */
  --color-primary: #617bff;
  --color-primary-light: #7d91fc;
  --color-primary-lighter: #97a7fb;
  --color-primary-lightest: #adb8ff;

  --color-secondary: rgb(61, 89, 234);
  --color-secondary-light: rgb(102, 122, 247);
  --color-yellow: #ffde59;

  --color-success: rgb(35, 134, 54);
  --color-success-light: rgb(50 219 83);

  --color-error: rgb(200, 0, 90);
  --color-error-secondary: rgb(240 94 160);
  --color-error-background: rgb(165, 65, 65);

  --color-text: white;
  --color-text-less-light: rgb(211, 211, 211);
  --color-text-secondary: rgb(193, 193, 193);
  --color-text-grey: rgb(164, 164, 164);
  --color-text-highlight: rgb(136, 158, 255);

  --color-background: rgb(14, 20, 27);
  --color-background-secondary: rgba(21, 30, 40, 1);
  --color-background-light: rgb(38, 50, 66);

  --color-card: rgb(24, 38, 53);
  --color-card-hover: rgba(38, 50, 66, 1);
  --color-card-dark: rgb(19, 27, 36);
  --color-card-less-light: rgb(52, 68, 91);
  --color-card-light: rgb(60, 77, 100);
  --color-card-lighter: rgb(72, 89, 112);

  --color-inactive-background: rgb(88, 88, 88);
  --color-modal-overlay: rgba(14, 20, 27, 0.8);
  --color-button-hover: rgba(255, 255, 255, 0.05);

  --color-divider-dark: rgb(48, 54, 61);
  --color-divider-light: rgb(128, 128, 128);

  --color-tooltip-bg: rgb(37, 37, 37);
  --color-tooltip-border: rgb(132, 132, 132);
}
